.main-sidebar{
    transition: all 0.3s ease-in-out;
    background: #fff;
    box-shadow: 0 5px 5px rgba(0,0,0,0.3) !important;
    z-index: 999;
    // min-height: calc(100% - 60px);
    // top: 60px;
    padding-top: 60px;
    // min-height: 100%;
    height: 100%;
    @media screen and (max-width: 415px) {
        width: 100%;
    }
    & > ::-webkit-scrollbar {
        display: none;
    }
    .sidebar{
        height: 100%;
        padding: 0;
        ul.nav-sidebar{
            overflow: unset;
            li.nav-header{
                text-align: center;
                // font-weight: bold;
                color: #555;
            }
            li.nav-item{
                &.has-treeview{
                    >.nav-link{
                        font-weight: bold;
                        font-family: 微軟正黑體;
                    }
                }
                .nav-link{
                    flex: 1;
                    display: flex;
                    align-items: center;
                    border: 0;
                    border-radius: 0;
                    color: #555;
                    padding: 10px 15px;
                    cursor: pointer;
                    &.active{
                        background-color: rgba(0,0,0,0.05);
                        box-shadow: none;
                        font-weight: bold;
                        font-family: 微軟正黑體;
                        @media screen and (max-width: 415px){
                            background-color: transparent;
                        }
                    }
                    &:hover{
                        background-color: rgba(0,0,0,0.05);
                    }
                    i.nav-icon{
                        font-size: unset;
                        width: unset;
                    }
                    p{
                        display: flex;
                        align-items: center;
                        animation: unset !important;
                        padding: 0 5px;
                        font-size: 0.95rem;
                        flex: 1;
                        i{
                            top: unset;
                            // line-height: 24px;
                        }
                    }
                }
                ul.nav-treeview{
                    padding: 5px 0;
                    display: block !important;
                    li.nav-item{
                        a.nav-link{
                            border: 0 !important;
                            overflow: unset;
                            position: relative;
                            font-size: 0.85rem;
                            // margin-bottom: 5px;
                            &:hover{
                                background-color: #f9f9f9 !important;
                                // color: #323232 !important;
                            }
                            &.active{
                                background-color: #f9f9f9 !important;
                                // color: #323232 !important;
                                &:before{
                                    height: 100%;
                                }
                            } 
                            &:before{
                                content: '';
                                position: absolute;
                                width: 5px;
                                height: 0;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                                background-color: #ffc864;
                                transition: height 0.15s ease-in-out;
                            }
                            i{
                                width: 25px;
                                text-align: center;
                                font-size: 18px;
                                font-weight: 100;
                            }
                        }
                    }
                }
            }
        }
    }
    .count{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #969696;
        color: #fff;
        margin-left: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 50%;
        &.active{
            background-color: #dc3545;
        }
    }
}

.sidebar-collapse .main-sidebar, .sidebar-collapse .main-sidebar::before{
    margin-left: -100%;
}

