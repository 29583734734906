/*!
 * Home styling for this template
 */

.login-page {
	background-image: url('../images/web/loigin_bg.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	padding-bottom: 30px;
	&::-webkit-scrollbar{
		display: none;
	}
	.logo{
		width: 150px;
		margin: 80px auto 15px auto;
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: center;
		&::before{
			content: "";
			display: block;
			padding-bottom: 40.8412483%;
		}
	}
	.title{
		margin: 10px 0 20px 0;
		color: #fff;
		text-align: center;
		font-weight: bold;
		font-size: 1.2rem;
		font-family: 微軟正黑體;
	}
	.login-container{
		max-width: 415px;
		width: calc(100% - 30px);
		padding: 15px;
		border-radius: 10px;
		background-color: #fff;
		margin: 0 auto;
		.MuiOutlinedInput-root{
			.MuiOutlinedInput-notchedOutline{
				border-color: #d9d9d9;
			}
			&:focus-within{
				.MuiOutlinedInput-notchedOutline{
					border-color: #3f51b5 !important;
				}
			}
			&:hover{
				.MuiOutlinedInput-notchedOutline{
					border-color: #d9d9d9;
				}
			}
		}
		.input-text{
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);	
		}
		.login-btn{
			width: 100%;
			padding: 10px 0;
			background-color: transparent;
			border: 0;
			border-radius: 5px;
			transition: all 0.15s ease-in-out;
			font-weight: bold;
			font-family: 微軟正黑體;
			background-color: rgba(19,34,53,.9);
			color: #fff;
			&:disabled{
				background-color: #969696;
				color: #666;
				pointer-events: none;
			}
		}
	}
	// min-height: 100%;
	// padding: 0;
	// overflow: hidden;
	// overflow-y: scroll;
	// position: relative;
	// background: radial-gradient(ellipse at center,#08223b 0,#000102 100%);
	// background: radial-gradient(ellipse at center, #63060c 0, #000 100%);
	// .login-box{
	// 	width: 300px;
	// 	border-radius: 10px;
	// 	background-color: #fff;
	// 	// background-color: #D70C18;
	// 	box-shadow: 0 0 5px rgba(255,255,255,1);
	// 	.card{
	// 		border: 0;
	// 		background-color: transparent;
	// 		margin: 0;
	// 		.card-body{
	// 			background-color: transparent;
	// 			.login-logo{
	// 				img{
	// 					width: 60%;
	// 				}
	// 			}
	// 			.login-box-msg {
	// 				font-size: 20px;
	// 				font-weight: bold;
	// 				color: #0b4182;
	// 				margin: 20px 0 10px 0;
					
	// 			}
	// 			.admin-input{
	// 				background-color: hsla(0,0%,100%,0.2);
	// 				// color: #fff;
	// 				border: 1px solid #ccc;
	// 				&::placeholder{
	// 					color: rgb(204, 204, 204);
	// 				}
	// 			}
	// 		}
	// 	}
	// }
}