.stat-card{
    margin-bottom: 20px;
    border-radius: 5px;
    color: #fff;
    box-shadow: 
    1px 2px 3px rgba(0,0,0,0.2),
    -3px 0 2px rgba(0,0,0,0.05) inset,
    3px 0 2px rgba(0,0,0,0.05) inset,
    0 -3px 6px rgba(0,0,0,0.2) inset,
    0 1px 4px rgba(0,0,0,0.05) inset;
    &.bg-red{
        background-color: #c74949 !important;
    }
    &.bg-green{
        background-color: #43A047 !important;
    }
    &.bg-blue{
        background-color: #5589c5 !important;
    }
    .stat-header{
        display: flex;
        align-items: center;
        padding: 15px;
        background-color: rgba(0,0,0,0.05);
        h6{
            font-weight: bold;
            margin-bottom: 0;
        }
        i{
            margin-left: 5px;
            cursor: help;
        }
    }
    .stat-body{
        padding: 10px 15px;
        font-size: 2.5rem;
        font-weight: 100;
        font-family: $fontNumber;
    }
}

.stat-card-popover {
    .popover{
        border-radius: 10px;
        background-color: #FFEA00;
        .popover-inner{
            padding: 15px;
            h3.popover-header {
                border-radius: 50px;
                background-color: rgba(0,0,0,0.075);
                font-weight: bold;
                padding: 15px 35px;
                border: 0;
                text-align: center;
                margin-bottom: 15px;
                &::before{
                    border-bottom: 1px solid #FFEA00;
                    border-top: 1px solid #FFEA00;
                }
            }
            .popover-body {
                padding: 0;
                font-size: 13px;
                line-height: 22px;
                letter-spacing: 1px;
            }
        }
        .arrow{
            &::before, &::after{
                border-bottom-color: #FFEA00;
                border-top-color: #FFEA00;
                // top: 2px !important;
            }
        }
    }
}

.pending-card{
    // border: 1px solid red;
    display: flex;
    background-color: #f5d54b;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 1px 2px 3px rgba(0,0,0,0.2);
    .pending-icon{
        width: 60px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0,0,0,0.1);
        color: #fff;
        i{
            font-size: 24px;
        }
    }
    .pending-content{
        flex: 1;
        height: 100px;
        padding: 0 15px;
        display: flex;
        // justify-content: center;
        align-items: center;
        color: #fff;
        position: relative;
        &::after{
            content: "\f105";
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            font-family: "Font Awesome 5 Pro";
            font-size: 1.5rem;
        }
        h6{
            font-size: 1.4rem;
            font-weight: bold;
            margin-bottom: 0;
        }
        .pending-amount{
            font-size: 2rem;
            font-family: $fontNumber;
        }
    }
}